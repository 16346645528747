@import "../variables";

.radio-group-wrapper {
  list-style: none;
  margin: 1rem 0 0 0;
  padding: 0;
  display: grid;
  grid-gap: 0.5em;
  grid-template-columns: 0.3fr 0.3fr;

  @media screen and (max-width: $screen-min-md) {
    grid-template-columns: 0.5fr 0.5fr;
  }
  @media screen and (max-width: $screen-min-sm) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }


}
