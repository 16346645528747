.loader {
  z-index: 10;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(240, 240, 240, 0.7);

  .content {
    padding: 2rem;
    min-width: 50%;
    background: #fff;
    border-radius: 0.5rem;
    max-height: 80vh;
  }

  .icon {
    font-size: 5rem;
  }

  .message {
    font-size: 3rem;
  }

}