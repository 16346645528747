$color-error: #f1241b;
$color-light-grey: #ECECEC;
$color-grey: #C9C9C9;
$color-dark-grey: #656565;
$color-light-black: #bd2020;
$color-black: #070707;
$color-white: #fff;
$color-red: #f1241b;
$color-test: #e7e7e7;
$color-invalid: #dc3545;

$side-bar-bg: #f5f1ea;

$font-size-headline: 1rem;

$font-size-label: 2rem;
$font-size-label-small: 1.75rem;
$font-size-label-extra-small: 1rem;

$font-size-text: 1.25rem;
$font-size-text-small: 1rem;
$font-size-text-big: 2rem;

$line-height: 3rem;
$line-height-2: 1.5rem;

// Variables for media queries
// @media (min-width: $screen-min-xs) { ... }
$screen-min-sm: 576px;
$screen-min-md: 768px;
$screen-min-lg: 992px;
$screen-min-xl: 1200px;
